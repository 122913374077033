<template>
    <section class="marques mb-3 p-3">    
        <article class="marques__bloc1 text-center">
            <h4>Des produits professionnels de qualité</h4>
            <p>J'ai choisi pour vous des marques haut de gamme que j'ai sélectionné pour leurs bienfaits reconnus et leurs qualités avérées</p>
            
            <b-row class="justify-content-center">
                <b-col sm="8" lg="4" class="marques__pics ml-3 mr-3 mt-3 p-2">
                    <a href="https://www.oliviagarden.eu/fr/" target="#"><img src="../../assets/logo-olivia-garden.jpg" alt="Logo Olivia Garden" class="img-fluid logo_marque"></a>
                </b-col>
                <b-col sm="8" lg="4" class="marques__pics ml-3 mr-3 mt-3 p-2">
                    <a href="https://www.myriam-kparis.com/" target="#"><img src="../../assets/logo_myriamk.png" alt="Logo Myriam K" class="img-fluid logo_marque"></a>
                </b-col>
            </b-row>
            <b-row class="justify-content-center">
                <b-col sm="8" lg="4" class="marques__pics ml-3 mr-3 mt-3 p-2">
                    <a href="http://www.revlon.fr/" target="#"><img src="../../assets/logo_proyou.png" alt="Logo Proyou" class="img-fluid logo_marque"></a>
                </b-col>
                <b-col sm="8" lg="4" class="marques__pics ml-3 mr-3 mt-3 p-2">
                    <a href="https://www.hairgum.com/" target="#"><img src="../../assets/Logo_hairgum.png" alt="Logo Wella" class="img-fluid logo_marque"></a>
                </b-col>
            </b-row>
        </article>        
    </section>    
</template>

<script>


export default {
    name: 'Maincontent',

    components: {
            
    },
    
}
</script>
<style scoped src="./Marques.css">

</style>