<template> 
    <div class="solidaire p-5">
        <h3 class="text-center mt-0">Je suis engagée au coté de Solidhair</h3>
        <b-row class="d-flex justify-content-center mt-5">
            <b-col sm="8" lg="6">        
                <a href="http://association-solidhair.fr/" target="#"><img src="../../assets/solidhair.jpg" alt="Image solidhair" class="solidaire__pics img-fluid"></a>   
            </b-col>
            <b-col sm="8" lg="6" class="solidaire__content">
                <p class="text-center p-3">Cette association (à but non lucratif) a pour objectif de collecter, grâce aux particuliers,
                    à des partenaires coiffeurs ou des dépositaires, des mèches de cheveux uniquement naturels de plus de 25 cm minimum, pour ensuite les vendre à des perruquiers.
                    Ainsi , avec l’argent collecté ils aident financièrement l’achat des prothèses capillaires des personnes atteintes du cancer et en difficultés financières partout en France,
                    afin de leur offrir un peu plus de dignité.
                </p> 
            </b-col>
        </b-row>       
    </div>   
</template>

<script>

export default {
    name: 'Solidaire', 
};

</script>

<style scoped src="./Solidaire.css">

</style>