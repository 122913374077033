<template>    
    <div class="sidebloc p-3">
        <div class="text-center">
            <h3 class="sidebar-title mb-3">RDV en ligne</h3>
            <p><em>Prenez rendez-vous en ligne sur mon agenda</em></p>
            <button class="btn-rdv wavyOpenButton shake" @click="handleClick">Prendre rendez-vous</button>
        </div>
        <div class="text-center mt-3 mb-3">
            <h3 class="sidebar-title mb-3">Actualité</h3>
            <a class="socialLink" href="https://www.facebook.com/audreybcoiffure" target="_blank">
                <img src="../../assets/facebook.png" alt="icone facebook" class="mr-3 shake" />
            </a>
            <a class="socialLink" href="https://www.instagram.com/audreybbeautee/?hl=fr" target="_blank">
                <img src="../../assets/instagram.png" alt="icone instagram" class="ml-3 shake" />
            </a>
        </div>
        <div class="text-center">
            <h3>Ouvert</h3>
            <p>Du lundi au samedi</p>
        </div>
        <div class="text-center">
            <h3>Adresse</h3>
            <p>47 rue de la libération<br>73300 Saint-Jean-de-Maurienne</p>
            <p><b>TEL: 04.79.64.20.57</b></p>
        </div>
    </div>
        
</template>

<script>
    import { onRdvBtnClick } from '../../main.js';

    export default {
        name: 'Sidebar',
        components: {        
        }, 
        mounted() {
        },
        methods: {
            handleClick() {
                onRdvBtnClick();
            }
        }
    }

</script>

<style scoped src="./Sidebar.css">

</style>