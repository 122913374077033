import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import Routes from './Routes'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

Vue.use(VueRouter)

Vue.use(BootstrapVue)

Vue.use(IconsPlugin)


const router = new VueRouter({
  routes: Routes,
  mode: 'history'
})

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')

export function onRdvBtnClick() {
    var container = document.getElementById('rdvPopupContainer');
    if (container) {
        container.style.display = 'block';
        var rdvPopupMaskElt;
        setTimeout(() => {
            if (!rdvPopupMaskElt) {
                rdvPopupMaskElt = document.getElementById('rdvPopupMask');
                rdvPopupMaskElt.addEventListener('click', () => {
                    var container = document.getElementById('rdvPopupContainer');
                    if (container) {
                        container.style.display = 'none';
                    }
                });
            }
        }, 100);
    }
}
