<template>
    <main id:="main-content" class="justify-content-center no-gutters mainbloc pb-5 pl-0 pr-0"> 
        <hero></hero>
        <div class="page__title text-center">
            <h1 class=" mb-5 mt-5">Mentions légales</h1>    
        </div> 
        <section class="bloc-1">       
            <b-row>
                <b-col lg="12" class="bloc-1__main mb-3">
                <menleg></menleg>                              
                </b-col>                
            </b-row>            
        </section>
    </main>
</template>

<script>

import Hero from '../../components/hero/Hero.vue'
import Mentionleg from '../../components/legalmentions/Legalmentions.vue'




export default {
    name: 'Mention',

    components: {
        'hero': Hero,     
        'menleg': Mentionleg,     
    } 
    
}
</script>

<style scoped src="./Mentions.css">

</style>