<template>
    <!-- Planity plugin display -->
    <div id="rdvPopupContainer">
        <div id="rdvPopupMask"></div>
        <div id="rdvPopupContent">
            <div id="planityContainer"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RdvPopup',
    mounted() {
        // Ensure the DOM is ready and load Planity after mounting
        const container = document.getElementById('planityContainer');
        
        // Set Planity configuration
        window.planity = {
            key: '-MUI_DfEu4Df2eozmaDy',
            primaryColor: '#bf6970',
            appointmentContainer: container
        };

        window.planity.options = {
            giftVoucherRedirect: () => {
                window.location = window.location.href;
            },
            appointmentRedirect: () => {
                window.location = window.location.href;
            },
        };

        // Dynamically load the Planity scripts if not already included
        const polyfillScript = document.createElement('script');
        polyfillScript.src = 'https://d2skjte8udjqxw.cloudfront.net/widget/production/2/polyfills.latest.js';
        document.body.appendChild(polyfillScript);

        const planityScript = document.createElement('script');
        planityScript.src = 'https://d2skjte8udjqxw.cloudfront.net/widget/production/2/app.latest.js';
        document.body.appendChild(planityScript);
    }
};
</script>

<style scoped src="./RdvPopup.css"></style>
