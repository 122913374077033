<template>
    <main id:="main-content" class="no-gutters mainbloc pb-5"> 
        <hero></hero>
        <div class="page__title text-center">
            <h1 class=" mb-5 mt-5">Me contacter</h1>    
        </div>
        <section class="bloc-1">       
            <b-row class="justify-content-center">
                <b-col lg="6" class="bloc-1__main mb-3">
                    <contactpics></contactpics>
                </b-col>               
                <b-col lg="6" class="bloc-1__side mb-3">
                    <gmap></gmap>                           
                </b-col>            
            </b-row>
                <contactbar></contactbar>
            <b-row class="bloc-2"></b-row>

                        
        </section>
        
    </main>
</template>

<script>

import Hero from '../../components/hero/Hero.vue'
import Gmap from '../../components/map/Map.vue'
import Contactpics from '../../components/contactpics/Contactpics.vue'
import Contactbar from '../../components/contactbar/Contactbar.vue'



export default {
    name: 'Contact',

    components: {
        'hero' : Hero,
        'gmap' : Gmap,
        'contactpics': Contactpics,
        'contactbar': Contactbar,
        

        
    } 
    
}
</script>

<style scoped src="./Contact.css">

</style>