<template>
    <section class="maincontent mb-3 p-5">    
        <article class="maincontent__bloc1">
            <div class="maincontent__photo">
                <img src="../../assets/audreypics.jpg" alt="Image de coiffure" class="img-fluid audreypics">
            </div>
            <div class="mb-3 maincontent__text">
                <h2 class="text-center"><em>Depuis 2016, le salon de coiffure Audrey B vous accueille à Saint Jean de Maurienne.</em></h2>
                <p>Je suis à votre écoute pour vous conseiller et vous proposer la coupe ou la mise en beauté qui mettra en valeur vos yeux, votre teint ou la forme de votre visage.
                <br>Je continue de me former régulièrement à de nouvelles techniques pour vous proposer des prestations modernes et un look tendance et actuel.</p>
                <p>Vous souhaitez une coupe élégante pour tous les jours, une mise en beauté pour votre mariage ou un autre événement ? Vous trouverez dans mon salon tout une gamme de prestations adaptées à vos cheveux: Lissage, mise en boucle, tye and dye, conturing ... </p>
            </div>
        </article>
          
        <article class="maincontent__bloc2 mt-3 p-3">
            <h4 class="text-center p-3">Envie de faire plaisir à vos proches ?</h4>
            <b-row class="justify-content-center">
                <b-col sm="10" lg="6" class="mt-3 blocpics ">
                    <div class="mx-auto">        
                        <img src="../../assets/present_pics.jpg" alt="Image bon_cadeau" class="img-fluid bloc2__pics mb-2"> 
                    </div>    
                </b-col>
                <b-col sm="12" lg="6" class="bloc2__content mt-3">
                    <p class="p-3">Quel que soit l'occasion: anniversaire, fête des mères ou juste pour le plaisir d'offrir...<br> 
                    Offrez une carte cadeau de la valeur de votre choix et soyez assuré de leur faire plaisir avec un vrai moment de détente.</p>
                </b-col>
            </b-row>                
        </article>
    </section>    
</template>

<script>


export default {
    name: 'Maincontent',

    components: {
            
    },
    
}
</script>
<style scoped src="./Maincontent.css">

</style>