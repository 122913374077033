<template>    
    <div class="contactbloc p-3">
        <div class="text-center">
            <h3>Ouvert</h3>
            <p>Du lundi au samedi</p>
        </div>
        <div class="text-center mb-3">
            <h3>Adresse</h3>
            <p>47 rue de la libération<br>73300 Saint-Jean-de-Maurienne</p>
            <p class="contact__tel"><b>TEL: 04.79.64.20.57</b></p>
            <h3 class="contactbar-title ">Actualité</h3>
            <a href="https://www.facebook.com/audreybcoiffure" target="_blank"><img src="../../assets/facebook.png" alt="icone facebook" class="mr-3 shake"><img></a>
            <a href="https://www.instagram.com/audreybbeautee/?hl=fr" target="_blank"><img src="../../assets/instagram.png" alt="icone instagram" class="ml-3 shake" ><img></a>
        </div>
    </div>
        
</template>

<script>

export default {
    name: 'Contactbar',
    components: {        
    } 
    
}
</script>

<style scoped src="./Contactbar.css">

</style>