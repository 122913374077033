<template>
  <div class="col md-12 navbloc">
    <div class="col md-12 d-flex justify-content-center">           
    <router-link :to="`/`">
      <img src="../../assets/logo_audreyb.png" alt="Logo AudreyB" class="navbar__logo">
    </router-link>
    </div>
    <div>
      <b-navbar toggleable="md" >
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav class="justify-content-center bloc_nav">
          <b-navbar-nav >
            <b-nav-item ><b-link to="/" class="pr-3">Accueil</b-link></b-nav-item>
            <b-nav-item ><b-link to="/Mes-prestations" class="pr-3">Mes prestations</b-link></b-nav-item>
            <b-nav-item ><b-link to="/Mes-produits" class="pr-3">Mes produits</b-link></b-nav-item>
            <b-nav-item ><b-link to="/Galerie-photo" class="pr-3">Galerie</b-link></b-nav-item>
            <b-nav-item ><b-link to="/Contact">Contact</b-link></b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar> 
    </div> 
    
  </div>
</template>

<script>

export default {
    name: 'Navbar', 
};

</script>

<style scoped src="./Navbar.css">

</style>