import Homepage from './views/homepage/Homepage'
import Prestations from './views/prestations/Prestations'
import Produits from './views/produits/Produits'
import Galerie from './views/galerie/Galerie'
import Contact from './views/contact/Contact'
import Mention from './views/mentions/Mentions'

export default [
    {path: '/', component: Homepage},
    {path: '/Mes-prestations', component: Prestations},
    {path: '/Mes-produits', component: Produits},
    {path: '/Galerie-photo', component: Galerie},
    {path: '/contact', component: Contact},
    {path: '/mentions-légales', component: Mention},
]    