<template>     
    <div class="picsbloc mx-auto">  
        <img src="../../assets/hairpic5.jpg" class="img-fluid" fluid alt="Logo AudreyB" ><img>
    </div>    
</template>

<script>



export default {
    name: 'Hero',

    components: {
    
    } 
}
</script>

<style scoped src="./Hero.css">

</style>