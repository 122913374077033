<template>
  <div id="app">
    <head-top></head-top>
    <router-view></router-view>
    <rdvPopup></rdvPopup>
    <foot-bot></foot-bot>
  </div>
</template>

<script>
  import Header from './components/header/Header.vue'
  import Footer from './components/footer/Footer.vue'
  import RdvPopup from './components/RdvPopup/RdvPopup.vue';


  export default {
    name: 'App',
    components: {
      'head-top': Header,    
      'foot-bot': Footer,  
      'rdvPopup': RdvPopup  
    }
  }
</script>

<style>
  .img-fluid {
    width: 100%;
    max-width: 100%;
    height:auto;
  }
</style>
