<template>
    <section class="mention mb-3 p-3">    
        <article>
            <b-row class="justify-content-center">
                <b-col sm="10" lg="8" class="ml-3 mr-3 mt-3 p-2">
                <h4 class="text-center mb-3">En vigueur au 15/11/2020</h4>
                <p>Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie
                    numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs du site "audreyb-coiffure.fr" les présentes mentions légales.
                    La connexion et la navigation sur le site "audreyb-coiffure" par l’utilisateur implique acceptation intégrale et sans réserve
                    des présentes mentions légales.
                    Ces dernières sont accessibles sur le site à la rubrique « Mentions légales ».
                </p>
                <article>
                    <h3 class="text-center">Art.1: L'éditeur</h3>
                    <p>L'édition du site audreyb-coiffure.fr est assurée par l'entreprise individuelle AudreyB, immatriculée au RCS de
                        Chambéry sous le numéro 82098289000015 dont le siège social est situé 47 rue de la libération 73300 Saint Jean de Maurienne, numéro de téléphone: 06.58.99.85.74,
                        adresse e-mail : audreyb@bbox.fr
                        N° de TVA intracommunautaire : FR51820982890
                        Le Directeur de la publication est Audrey DUCHER.
                    </p>
                </article>
                <article>
                    <h3 class="text-center">Art.2: L'hébergeur</h3>
                    <P>
                    L’hébergeur du site www.audreyb-coiffure.fr est la Société LWS, dont le siège social est situé au S.A.R.L au capital de 500 000 Euros 10, RUE PENTHIEVRE 75008 PARIS.
                    </P>
                </article>
                <article>
                    <h3 class="text-center">Art.3: Accès au site</h3>
                    <p>Le site est accessible par tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption programmée ou non et pouvant
                        découler d’une nécessité de maintenance.
                        En cas de modification, interruption ou suspension des services le site audreyb-coiffure.fr ne saurait être tenu responsable
                    </p>
                </article>
                <article>
                    <h3 class="text-center">Art.4: Collecte de données</h3>
                    <p>
                        Le site est exempté de déclaration à la Commission Nationale Informatique et Libertés (CNIL) dans la mesure où il ne collecte
                        aucune donnée concernant les utilisateurs.
                    </p>
                </article>
                <article>
                    <h3 class="text-center">Art.: Cookies</h3>
                    <p>L’Utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de
                        navigation.
                        En naviguant sur le site, il les accepte.
                        Un cookie est un élément qui ne permet pas d’identifier l’Utilisateur mais sert à enregistrer des informations relatives à la
                        navigation de celui-ci sur le site Internet. L’Utilisateur pourra désactiver ce cookie par l’intermédiaire des paramètres figurant au
                        sein de son logiciel de navigation.
                    </p>
                </article>
                <article>
                    <h3 class="text-center">Art.6: Propriété intellectuelle</h3>
                    <p>Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie du site "audreyb-coiffure.fr", sans
                        autorisation de l’Editeur est prohibée et pourra entraînée des actions et poursuites judiciaires telles que notamment prévues par
                        le Code de la propriété intellectuelle et le Code civil.
                    </p>
                </article>   
                </b-col>
            </b-row>
        </article>        
    </section>    
</template>

<script>


export default {
    name: 'legalmention',

    components: {
            
    },
    
}
</script>
<style scoped src="./Legalmentions.css">

</style>