<template>
    <main id:="main-content" class="justify-content-center no-gutters mainbloc pb-5 pl-0 pr-0"> 
        <hero></hero>
        <div class="page__title text-center">
            <h1 class=" mb-5 mt-5">Mes prestations</h1>    
        </div> 
        <section class="bloc-1">       
            <b-row>
                <b-col lg="12" class="bloc-1__main mb-3">
                    <presta></presta>                           
                </b-col>                
            </b-row>            
        </section>
    </main>
</template>

<script>

import Hero from '../../components/hero/Hero.vue'
import Presta from '../../components/presta/Presta.vue'


export default {
    name: 'Prestations',

    components: {
        'hero': Hero,
        'presta' : Presta,
        
    }
    
    
}
</script>

<style scoped src="./Prestations.css">

</style>