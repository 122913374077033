<template> 
    <div class="gmap text-center p-3">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2807.5044569853703!2d6.34160871576228!3d45.278027053867405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478a2ff970195769%3A0x1d2b4433146707e!2sAudrey%20B!5e0!3m2!1sfr!2sfr!4v1604930884667!5m2!1sfr!2sfr" width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    </div>    
</template>

<script>

export default {
    name: 'Map', 
};

</script>

<style scoped src="./Map.css">

</style>