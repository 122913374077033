<template>
    <div class="col-md-12 pt-3 footer ">
        <div class="footer__credit">
            <p>AudreyB coiffure - 2020 | Created by: <a href="https://agence-ginkgo.fr" class="link">Agence-ginkgo</a></p>
        </div>
        <div class="footer__mention pb-2">
            <b-link to="/mentions-légales" class="pr-3 link">Mentions légales</b-link>
        </div>
    </div>   
    
</template>

<script>



export default {
    name: 'Footer',

    components: {
    
    } 
}
</script>

<style scoped src="./Footer.css">

</style>