<template>
    <section class="picsgals mb-3 p-3">            
        <b-col lg="6" class="mx-auto">
            <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="4000"
            controls
            indicators
            background="#ababab"
            style="text-shadow: 1px 1px 2px #333;"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
            >

            <b-carousel-slide img-src="../../assets/gallery11.jpg"></b-carousel-slide>
            <b-carousel-slide img-src="../../assets/gallery8.jpg"></b-carousel-slide>
            <b-carousel-slide img-src="../../assets/gallery13.jpg"></b-carousel-slide>
            <b-carousel-slide img-src="../../assets/gallery1.jpg"></b-carousel-slide>
            <b-carousel-slide img-src="../../assets/gallery2.jpg"></b-carousel-slide>
            <b-carousel-slide img-src="../../assets/gallery3.jpg"></b-carousel-slide>
            <b-carousel-slide img-src="../../assets/gallery4.jpg"></b-carousel-slide>
            <b-carousel-slide img-src="../../assets/gallery5.jpg"></b-carousel-slide>
            <b-carousel-slide img-src="../../assets/gallery7.jpg"></b-carousel-slide>
            <b-carousel-slide img-src="../../assets/gallery9.jpg"></b-carousel-slide>
            <b-carousel-slide img-src="../../assets/gallery10.jpg"></b-carousel-slide>
            <b-carousel-slide img-src="../../assets/gallery12.jpg"></b-carousel-slide>
            <b-carousel-slide img-src="../../assets/gallery14.jpg"></b-carousel-slide>
            <b-carousel-slide img-src="../../assets/gallery15.jpg"></b-carousel-slide>
            <b-carousel-slide img-src="../../assets/gallery16.jpg"></b-carousel-slide>
            <b-carousel-slide img-src="../../assets/gallery17.jpg"></b-carousel-slide>
            <b-carousel-slide img-src="../../assets/gallery18.jpeg"></b-carousel-slide>
            
            </b-carousel>
        </b-col>                     
    </section>    
</template>

<script>



export default {
    name: 'Picsgallery',
    
    components: {
               
    },
    
}
</script>
<style scoped src="./Picsgallery.css">

</style>