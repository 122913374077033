<template>
    <main id:="main-content" class="no-gutters mainbloc pb-5"> 
        <hero></hero>
        <div class="page-produit__title text-center">
            <h1 class=" mb-5 mt-5">Mes marques partenaires</h1>    
        </div> 
        <section class="bloc-1">       
            <b-row>
                <b-col lg="12" class="bloc-1__main mb-3">
                    <marques></marques>                        
                </b-col>                
            </b-row>            
        </section>
    </main>
</template>

<script>

import Hero from '../../components/hero/Hero.vue'
import Marques from '../../components/marques/Marques.vue'


export default {
    name: 'Produits',

    components: {
        'hero' : Hero,
        'marques' : Marques,        
    } 
    
}
</script>

<style scoped src="./Produits.css">

</style>