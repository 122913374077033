<template>
    <main id:="main-content" class="mainbloc pb-5"> 
        <hero></hero>
        <div class="page__title text-center">
            <h1 class=" mb-5 mt-5">Audrey B, la créativité au service de votre beauté</h1>    
        </div>
        <section class="bloc-1">       
            <b-row>
                <b-col lg="9" class="bloc-1__main mb-3">
                    <maincontent></maincontent>                        
                </b-col>
                <b-col lg="3" class="bloc-1__side mb-3">
                    <sidebar></sidebar>
                    <promo></promo>
                </b-col>            
            </b-row>            
        </section>
        <section class="bloc-2">
            <solidaire></solidaire>    
        </section>
    </main>
</template>

<script>

import Hero from '../../components/hero/Hero.vue'
import Maincontent from '../../components/maincontent/Maincontent.vue'
import Sidebar from '../../components/sidebar/Sidebar.vue'
import Promo from '../../components/promo/Promo.vue'
import Solidaire from '../../components/solidaire/Solidaire.vue'

export default {
    name: 'Homepage',

    components: {
        'hero' : Hero,
        'maincontent' : Maincontent,
        'sidebar': Sidebar,
        'promo': Promo,
        'solidaire': Solidaire,
    },
    
}
</script>

<style scoped src="./Homepage.css">

</style>