<template> 
    <div class="contactpics text-center p-3">
        <img src="../../assets/salon.jpeg" alt="photo salon de coiffure" class="img-fluid">
    </div>    
</template>

<script>

export default {
    name: 'Map', 
};

</script>

<style scoped src="./Contactpics.css">

</style>