<template>
    <div class="container-fluid no-gutters">
        <div class="row">
        <navbar></navbar>
        </div>
    </div>
</template>

<script>

import Navbar from '../../components/navbar/Navbar.vue'


export default {
    name: 'Head-top',

    components: {
        'navbar': Navbar,
        
    } 
}
</script>

<style scoped src="./Header.css">

</style>