<template>    
    <div class="promo text-center p-3 mt-3">
        <h4>Offre étudiants</h4>
        <p>Vous êtes étudiant(e) ? Sur présentation de votre carte, bénéficiez d'une remise de 10 % sur votre coupe.</p>
    </div>
</template>

<script>

export default {
    name: 'Promo', 
};

</script>

<style scoped src="./Promo.css">

</style>